import React from 'react';
import axios from 'axios';
import { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
// import { useNavigate } from 'react-router-dom';
import './registerteambd.css';
// import UpdateTeam from './UpdateTeam';


function TournamentBdEmbassy2025() {
  const [teams, setTeams] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [msg, setMsg] = useState("");
  const [teamData, setTeamData] = useState(null);
  const [buttonDisabled, setButtonDisabled] = useState(true);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const tableName = "bd_embassy_tournament_2025";
  const url1 = "https://ssbc.club/db/teams.php";
  // const url1 = "https://script.google.com/macros/s/AKfycbyFBqtrc4bW3HlzfPUeJWSkEYRF5lZYSKWktF8sVi9ErmsSZ2LKNb40Qmgao0g3rs9_/exec";
  const url2 = "https://ssbc.club/db/handle_team.php";

  useEffect(() => {
    const url = 'https://script.google.com/macros/s/AKfycbyFBqtrc4bW3HlzfPUeJWSkEYRF5lZYSKWktF8sVi9ErmsSZ2LKNb40Qmgao0g3rs9_/exec';

    fetch(url)
      .then(response => response.json())
      .then(data => {
        console.log(data.data);
        setTeams(data.data); // Update the state with JSON data
        setLoading(false);
      })
      .catch(error => {
        setError(error.message);
        setLoading(false);
      });
  }, []);

  //   useEffect(()=>{
  //     getTeams();
  //  }, []);

  // function getTeams() {
  //   //axios.post(url,"bd_embassy_tournament_2025")
  //   axios.post(url1, { table: tableName })
  //     .then(function (response) {
  //       console.log(response.data);
  //       setTeams(response.data);
  //     })
  // }

  const handleEditClick = (team) => {
    // console.log(team);
    setMsg("");
    setTeamData(team);
    setButtonDisabled(true)
    setModalShow(true);
  }

  const handleModalClose = (event) => {
    // console.log(document.getElementById('delete').value)
    if (event) {
      const formElm = document.querySelector('form');
      const fData = new FormData(formElm);
      fData.append('table', tableName);

      if (event.target.value === 'delete') {
        fData.append('request', "deleteteam");
        console.log(event.target.value);
        axios.post(url2, fData)
          .then(response => {
            console.log(response.data)
            if (response.data === "Successful") {
              setModalShow(false);
              window.location.href = "/tournamentbdembassy2025";
            } else {
              setMsg(response.data);
            }
          })
          .catch(error => {
            setMsg(error)
          });
      } else { // update
        fData.append('request', "updateteam");
        console.log(event.target.value);
        axios.post(url2, fData)
          .then(response => {
            console.log(response.data)
            if (response.data === "Successful") {
              setModalShow(false);
              window.location.href = "/tournamentbdembassy2025";
            } else {
              setMsg(response.data);
            }
          })
          .catch(error => {
            setMsg(error)
          });
        //console.log(event.target.value);
      }
    } else {
      setModalShow(false);
    }
  }
  const handleCheckbox = () => {
    // console.log(document.getElementById('checkbox').checked)
    if (document.getElementById('checkbox').checked) {
      setButtonDisabled(false)
    } else {
      setButtonDisabled(true)
    }

  }

  return (
    <div className="container">
      <div className="mt-2 p-4 p-md-5 mb-4 rounded bd-bg-color text-white">
        <div className="p-2 border border-white rounded text-center bd-bg-gradient-down">
          <h3>Registered teams for Bangladesh Embassy Tournament 2025</h3>
        </div>
        <h4 className='mt-2'>Boys A</h4>
        <div className="p-2 border border-white rounded text-center">
          <table className='table table-striped table-dark'>
            <thead>
              <tr>
                <th>Id</th>
                <th>Player</th>
                <th>Partner</th>
                <th>Phone</th>
                <th>Payment</th>
                {localStorage.getItem('state') === "Logout" && (
                  <th>Action</th>
                )}

              </tr>
            </thead>
            <tbody>
              {teams
                .filter(team => team.Level === "Men's Double A") // Adjusted filter condition to match the JSON data
                .map((team, key) => (
                  <tr key={key}>
                    <td>{key + 1}</td> {/* Display index + 1 as ID */}
                    <td>{team["Player's Name"]}</td> {/* Adjusted key for player's name */}
                    <td>{team["Partner's Name"]}</td> {/* Adjusted key for partner's name */}
                    <td>{team.Phone}</td> {/* Adjusted key for phone */}
                    <td>{team.Payment || "Pending"}</td> {/* Display payment or default to "Pending" */}
                    {localStorage.getItem('state') === "Logout" && (
                      <td>
                        <button onClick={() => handleEditClick(team)}>Edit</button>
                      </td>
                    )}
                  </tr>
                ))}

            </tbody>
          </table>
        </div>
        <h4>Boys B</h4>
        <div className="p-2 border border-white rounded text-center">
          <table className='table table-striped table-dark'>
            <thead>
              <tr>
                <th>Id</th>
                <th>Player</th>
                <th>Partner</th>
                <th>Phone</th>
                <th>Payment</th>
                {localStorage.getItem('state') === "Logout" && (
                  <th>Action</th>
                )}
              </tr>
            </thead>
            <tbody>
            {teams
                .filter(team => team.Level === "Men's Double B") // Adjusted filter condition to match the JSON data
                .map((team, key) => (
                  <tr key={key}>
                    <td>{key + 1}</td> {/* Display index + 1 as ID */}
                    <td>{team["Player's Name"]}</td> {/* Adjusted key for player's name */}
                    <td>{team["Partner's Name"]}</td> {/* Adjusted key for partner's name */}
                    <td>{team.Phone}</td> {/* Adjusted key for phone */}
                    <td>{team.Payment || "Pending"}</td> {/* Display payment or default to "Pending" */}
                    {localStorage.getItem('state') === "Logout" && (
                      <td>
                        <button onClick={() => handleEditClick(team)}>Edit</button>
                      </td>
                    )}
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        <h4>Boys C</h4>
        <div className="p-2 border border-white rounded text-center">
          <table className='table table-striped table-dark'>
            <thead>
              <tr>
                <th>Id</th>
                <th>Player</th>
                <th>Partner</th>
                <th>Phone</th>
                <th>Payment</th>
                {localStorage.getItem('state') === "Logout" && (
                  <th>Action</th>
                )}
              </tr>
            </thead>
            <tbody>
            {teams
                .filter(team => team.Level === "Men's Double C") // Adjusted filter condition to match the JSON data
                .map((team, key) => (
                  <tr key={key}>
                    <td>{key + 1}</td> {/* Display index + 1 as ID */}
                    <td>{team["Player's Name"]}</td> {/* Adjusted key for player's name */}
                    <td>{team["Partner's Name"]}</td> {/* Adjusted key for partner's name */}
                    <td>{team.Phone}</td> {/* Adjusted key for phone */}
                    <td>{team.Payment || "Pending"}</td> {/* Display payment or default to "Pending" */}
                    {localStorage.getItem('state') === "Logout" && (
                      <td>
                        <button onClick={() => handleEditClick(team)}>Edit</button>
                      </td>
                    )}
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        <h4>Girls</h4>
        <div className="p-2 border border-white rounded text-center">
          <table className='table table-striped table-dark'>
            <thead>
              <tr>
                <th>Id</th>
                <th>Player</th>
                <th>Partner</th>
                <th>Phone</th>
                <th>Payment</th>
                {localStorage.getItem('state') === "Logout" && (
                  <th>Action</th>
                )}
              </tr>
            </thead>
            <tbody>
            {teams
                .filter(team => team.Level === "Women's Double") // Adjusted filter condition to match the JSON data
                .map((team, key) => (
                  <tr key={key}>
                    <td>{key + 1}</td> {/* Display index + 1 as ID */}
                    <td>{team["Player's Name"]}</td> {/* Adjusted key for player's name */}
                    <td>{team["Partner's Name"]}</td> {/* Adjusted key for partner's name */}
                    <td>{team.Phone}</td> {/* Adjusted key for phone */}
                    <td>{team.Payment || "Pending"}</td> {/* Display payment or default to "Pending" */}
                    {localStorage.getItem('state') === "Logout" && (
                      <td>
                        <button onClick={() => handleEditClick(team)}>Edit</button>
                      </td>
                    )}
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        <h4>Kids</h4>
        <div className="p-2 border border-white rounded text-center">
          <table className='table table-striped table-dark'>
            <thead>
              <tr>
                <th>Id</th>
                <th>Player</th>
                <th>Partner</th>
                <th>Phone</th>
                <th>Payment</th>
                {localStorage.getItem('state') === "Logout" && (
                  <th>Action</th>
                )}
              </tr>
            </thead>
            <tbody>
            {teams
                .filter(team => team.Level === "Kids Double") // Adjusted filter condition to match the JSON data
                .map((team, key) => (
                  <tr key={key}>
                    <td>{key + 1}</td> {/* Display index + 1 as ID */}
                    <td>{team["Player's Name"]}</td> {/* Adjusted key for player's name */}
                    <td>{team["Partner's Name"]}</td> {/* Adjusted key for partner's name */}
                    <td>{team.Phone}</td> {/* Adjusted key for phone */}
                    <td>{team.Payment || "Pending"}</td> {/* Display payment or default to "Pending" */}
                    {localStorage.getItem('state') === "Logout" && (
                      <td>
                        <button onClick={() => handleEditClick(team)}>Edit</button>
                      </td>
                    )}
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
      <Modal
        show={modalShow}
        onHide={handleModalClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Update Team
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <div className="p-2 border border-white rounded">
            <p className='text-danger'>{msg}</p>
            {teamData != null &&
              <form className="mt-2 needs-validation" id="updateform">
                <div class="mt-2 form-group col-md-8">
                  <label for="firstname">Id: </label>
                  <input
                    type="text"
                    className="form-control"
                    id="id"
                    name="id"
                    value={teamData.id}
                  // disabled
                  />
                </div>
                <div class="mt-2 form-group col-md-8">
                  <label for="firstname">Player Name: </label>
                  <input
                    type="text"
                    className="form-control"
                    id="player"
                    name="player"
                    value={teamData.player}
                    onChange={(e) =>
                      setTeamData((prevState) => ({
                        ...prevState,
                        player: e.target.value,
                      }))
                    }
                  />
                </div>
                <div class="mt-2 form-group col-md-8">
                  <label for="lastname">Partner Name: </label>
                  <input
                    type="text"
                    className="form-control"
                    id="partner"
                    name="partner"
                    value={teamData.partner}
                    onChange={(e) =>
                      setTeamData((prevState) => ({
                        ...prevState,
                        partner: e.target.value,
                      }))
                    }
                  />
                </div>
                <div class="mt-2 form-group col-md-8">
                  <label for="lastname">Group:</label>
                  <select className='ms-2'
                    name="level"
                    value={teamData.level}
                    onChange={(e) =>
                      setTeamData((prevState) => ({
                        ...prevState,
                        level: e.target.value,
                      }))
                    }>
                    <option value="A">A</option>
                    <option value="B">B</option>
                    <option value="C">C</option>
                    <option value="Girls">Girls</option>
                    <option value="Kids">Boys</option>
                  </select>
                </div>
                <div className="mt-2 form-group col-md-8">
                  <label for="email">Email address</label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    name="email"
                    aria-describedby="emailHelp"
                    value={teamData.email}
                    onChange={(e) =>
                      setTeamData((prevState) => ({
                        ...prevState,
                        email: e.target.value,
                      }))
                    }
                  />
                </div>
                <div class="mt-2 form-group col-md-4">
                  <label for="phone">Phone Number: </label>
                  <input
                    type="number"
                    class="form-control"
                    id="phone"
                    name="phone"
                    pattern="[0-9]*"
                    value={teamData.phone}
                    onChange={(e) =>
                      setTeamData((prevState) => ({
                        ...prevState,
                        phone: e.target.value,
                      }))
                    }
                  />
                </div>
                <div class="mt-2 form-group col-md-8">
                  <label for="address">City: </label>
                  <input
                    type="text"
                    className="form-control"
                    id="city"
                    name="city"
                    value={teamData.city}
                    onChange={(e) =>
                      setTeamData((prevState) => ({
                        ...prevState,
                        city: e.target.value,
                      }))
                    }
                  />
                </div>
                <div class="mt-2 form-group col-md-8">
                  <label for="address">Payment: </label>
                  <select className='ms-2'
                    name="payment"
                    value={teamData.payment}
                    onChange={(e) =>
                      setTeamData((prevState) => ({
                        ...prevState,
                        payment: e.target.value,
                      }))
                    }
                  >
                    <option value="Paid">Paid</option>
                    <option value="Pending">Pending</option>
                  </select>
                </div>

                <div class="mt-3 form-check">
                  <input
                    id="checkbox"
                    type="checkbox"
                    name="agreement"
                    class="form-check-input"
                    onChange={handleCheckbox}
                  />

                  <label for="agreement">
                    Are you sure you want to update or delete this team?
                  </label>
                </div>
              </form>
            }
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button id='delete' value='delete' onClick={handleModalClose} disabled={buttonDisabled} >Delete</Button>
          <Button id='update' value='update' onClick={handleModalClose} disabled={buttonDisabled}>Update</Button>
        </Modal.Footer>
      </Modal>
    </div >
  )
}
export default TournamentBdEmbassy2025;