import React from "react";
import Slide1 from '../images/ssbcAd2021.jpg';
import Slide2 from '../images/ssbcAd2022.jpg';
import Slide3 from '../images/ssbcAd2023.jpg';
import Slide4 from '../images/ssbcAd2024.jpg';
import Slide5 from '../images/ssbcAd2025_0.jpg';
import Carousel from 'react-bootstrap/Carousel';

function SlideShow() {
  return (
    <div className="container">
        <Carousel variant="dark">
        <Carousel.Item>
                <div className="mt-2 p-4 p-md-5 mb-4 rounded text-bg-dark">
                    <img
                        className="mb-5 d-block w-100"
                        src={Slide5}
                        alt="Third slide"
                    />
                    <Carousel.Caption className='text-white'>
                    <h5>Bangladesh Embassy Tournament 2025</h5>
                    {/* <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p> */}
                    </Carousel.Caption>
                </div>
            </Carousel.Item>
            <Carousel.Item>
                <div className="mt-2 p-4 p-md-5 mb-4 rounded text-bg-dark">
                    <img
                        className="mb-5 d-block w-100"
                        src={Slide4}
                        alt="Third slide"
                    />
                    <Carousel.Caption className='text-white'>
                    <h5>Tournament 2024</h5>
                    {/* <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p> */}
                    </Carousel.Caption>
                </div>
            </Carousel.Item>
            <Carousel.Item>
                <div className="mt-2 p-4 p-md-5 mb-4 rounded text-bg-dark">
                    <img
                        className="mb-5 d-block w-100"
                        src={Slide3}
                        alt="Third slide"
                    />
                    <Carousel.Caption className='text-white'>
                    <h5>Tournament 2023</h5>
                    {/* <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p> */}
                    </Carousel.Caption>
                </div>
            </Carousel.Item>
            <Carousel.Item>
                <div className="mt-2 p-4 p-md-5 mb-4 rounded text-bg-dark">
                    <img
                        className="mb-5 d-block w-100"
                        src={Slide2}
                        alt="Second slide"
                    />
                    <Carousel.Caption className='text-white'>
                    <h5>Tournament 2022</h5>
                    {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> */}
                    </Carousel.Caption>
                </div>
            </Carousel.Item>
            <Carousel.Item>
                <div className="mt-2 p-4 p-md-5 mb-4 rounded text-bg-dark">
                    <img
                        className="mb-5 d-block w-100"
                        src={Slide1}
                        alt="First slide"
                    />
                    <Carousel.Caption className='text-white'>
                    <h5>Tournament 2021</h5>
                    {/* <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p> */}
                    </Carousel.Caption>
                </div>
            </Carousel.Item>


        </Carousel>
    </div>
  );
}

export default SlideShow;