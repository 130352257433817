import React from 'react';
// import { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
// import $ from "jquery";

function Admin() {

    // const [email, setEmail] = useState('');
    // const [password, setPassword] = useState('');

    // const handleChange = (e) => {
    //     setName(e.target.value)
    // }

    //  // login the user
    // const handleSubmit = (e) =>
    // console.log(e);
    //     e.preventDefault();
    //     const form = $(e.target);
    //     $.ajax({
    //         type: "POST",
    //         url: form.attr('action'),
    //         data: form.serialize(),
    //         success(data){
    //             setResult(data);
    //         }
    //     }) 

    // // logout the user

    // const handleLogout = () => {
    //     setEmail("");
    //     setPassword("");
    //     localStorage.clear();
    // }


    return (
        // <div className="App">
        //     <form
        //         action="http://playbook.scienceontheweb.net/dataQuery.php"
        //         method="post"
        //         onSubmit={(event) => handleSubmit(event)}>
        //         <label htmlFor="email">Email: </label>
        //         <input
        //             type="email"
        //             id="email"
        //             name="email"
        //             value={email}
        //             onChange={(event) => handleChange(event)}
        //         />
        //         <br/>

        //         <button type="submit">Submit</button>
        //     </form>
        //     <h1>{result}</h1>
        // </div>



        <div className="container">
            <div className="mt-2 p-4 p-md-5 mb-4 rounded text-bg-dark">
                <div className="p-2 border border-white rounded text-center">
                    <h5>Admin</h5>
                </div>
            </div>
        </div>
    )};

export default Admin;