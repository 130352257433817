import React from "react";
import 'bootstrap/dist/css/bootstrap.css';
import SlideShow from "./Slideshow";
import Cards from './HomeCards'

import { Link } from "react-router-dom";

function Home() {
  return (
    <div className="album bg-light">
      <SlideShow/>
      <div className="container">
        <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3">
          <Cards
            title = 'Current Tournament'
            description = 'Registration open for the tournament “Bangladesh Embassy Tournament 2025”.'
            button1 = {<Link className="btn btn-secondary" type="button" to='/registerteam'>Register to the tournament</Link>}
          />
          <Cards
            title = 'Club Members'
            description = 'We as a member of the SSBC Club represent Bangladeshi badminton players in Sweden. And participate in the tournaments of Swedish badminton federation.'
            button1 = {<Link className="btn btn-secondary" type="button" to='/registermember'>Register as a member</Link>}
            button2 = {<Link className="btn btn-secondary" type="button" to='/members'>Members</Link>}
          />
          <Cards
            title = 'Events'
            description = 'Find all the events organized by Stockholm Smashers Badminton Club. Click on the event to find more detail.'
            button5 = {<Link className="btn btn-secondary" type="button" to='/tournamentbdembassy2025'>Bangladesh Embassy Torunament 2025</Link>}
            button4 = {<Link className="btn btn-secondary" type="button" to='/tournament2024'>Stockholm Autumn Smash 2024</Link>}
            button3 = {<Link className="btn btn-secondary" type="button" to='/tournament2023'>Stockholm Autumn Smash 2023</Link>}
            button2 = {<Link className="btn btn-secondary" type="button" to='/tournament2022'>Stockholm Autumn Smash 2022</Link>}
            button1 = {<Link className="btn btn-secondary" type="button" to='/tournament2021'>Stockholm Autumn Smash 2021</Link>}
          />
        </div>
      </div>
    </div>
  )
}
export default Home;