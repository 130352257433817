// import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
// import axios from 'axios';
// import Contacts from "./Contacts"

// import { useNavigate } from 'react-router-dom';

function Test() {
 
    const currentYear = new Date().getFullYear();
    const currentDate = new Date();
    const comparisonDate = new Date("2024-10-25");
    const nextYear = new  Date("2024-06-30");
    
    

    console.log("current year:",currentYear);
    console.log("current date:",currentDate);
    console.log("comparison date:",comparisonDate);
    console.log("next date: ", nextYear);
    

    if (currentDate < comparisonDate) {
        console.log("The current date is before June 30, 2024.");
    } else {
        console.log("The valid date is till ", nextYear.toISOString().split('T')[0]);
    }

}
export default Test;