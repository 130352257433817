import React from "react";
import { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import axios from 'axios';

import Notification from '../Notification';
import "./registerteambd.css";

function RegisterTeamBd() {
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [linkTo, setLinkTo] = useState("");
  const [showPart, setShowPart] = useState(true);

  const currentDate = new Date();
  const comparisonDate = new Date("2025-02-17");

  const tableName = "bd_embassy_tournament_2025";
  const tournament = "tournamentbdembassy2025";
  // const url = "https://ssbc.club/db/all_posts.php";
  const url = "https://ssbc.club/db/team_reg.php";
  const apiUrl = "https://ssbc.club/emailApi/email.php";

  useEffect(() => {
    console.log("Current Date: ", currentDate);
    console.log("Comparioson Date: ", comparisonDate);
    if (currentDate > comparisonDate) {
      console.log("date expired");
      setShowPart(false);
    } else {
      setShowPart(true);
    }
  })

  function Submit(e) {
    document.getElementById("register").disabled = true;
    const formElm = document.querySelector('form');
    e.preventDefault();

    const fData = new FormData(formElm);
    fData.append('request', "teamreg");
    
    fData.append('table', tableName);

    axios.post(url, fData)
      .then(response => {
        if (response.data === "Successful") {
          sendEmail(fData);
          document.getElementById("teamForm").reset();
        } else {
          setTitle("Warning");
          setMessage(response.data);
          setModalShow(true);
        }
        document.getElementById("register").disabled = false;

      })
      .catch(error => {
        alert(error)
        setTitle("Error");
        setMessage(error);
        setModalShow(true);
        document.getElementById("register").disabled = false;
      });
  }

  const sendEmail = (fData) => {
    fData.append('request', 'teamreg');
    axios.post(apiUrl, fData)
      .then(response => {
        setLinkTo("/".tournament)
        setTitle("Registration Completed");
        setMessage(response.data);
        setModalShow(true);
      })
      .catch(error => {
        setTitle("Failed to Register");
        setMessage(error);
        setModalShow(true);

      })
  }

  return (
    <div className="container">
      <div className="mt-2 p-4 p-md-5 mb-4 rounded bd-bg-color text-white">
        <div className="p-2 border border-white rounded text-center bd-bg-gradient-down">
          <h5>Bangladesh Embassy Tournament Entry Form</h5>
        </div>
        <div className={showPart ? "form-visible" : "form-hidden"}>
          <form className="mt-2 mb-3 needs-validation bd-bg-img" id="teamForm" onSubmit={(e) => Submit(e)}>
            <div class="mt-2 form-group col-md-8">
              <label for="player">Player's Name: </label>
              <input
                type="text"
                className="form-control"
                id="player"
                name="player"
                required
              />
            </div>
            <div class="mt-2 form-group col-md-8">
              <label for="partner">Partner's Name: </label>
              <input
                type="text"
                class="form-control"
                id="partner"
                name="partner"
                required
              />
            </div>
            <div class="mt-3 form-group col-md-3">
              <select
                class="form-select"
                aria-label="Default select example"
                id="level"
                name="level"
                required >
                <option selected>Select Group </option>
                <option value="A">A</option>
                <option value="B">B</option>
                <option value="C">C</option>
                <option value="Girls">Girls</option>
                <option value="Kids">Kids</option>
              </select>
            </div>
            <div className="mt-2 form-group col-md-8">
              <label for="email">Email address</label>
              <input
                type="email"
                class="form-control"
                id="email"
                name="email"
                required
              />
            </div>
            <div class="mt-2 form-group col-md-4">
              <label for="phone">Phone Number: </label>
              <input
                type="number"
                class="form-control"
                id="phone"
                name="phone"
                pattern="[0-9]*"
                required
              />
            </div>
            <div class="mt-2 form-group col-md-4">
              <label for="City">City: </label>
              <input
                type="text"
                class="form-control"
                id="city"
                name="city"
                required
              />
            </div>

            <div class="mt-3 form-check">
              <input
                type="checkbox"
                name="agreement"
                class="form-check-input"
                required
              />
              <label for="agreement">
                I agree to the terms and conditions
              </label>
            </div>

            <div className="form-group mt-3">
              <button
                type="submit"
                id="register"
                class="btn text-white red-button"
              >Submit</button>
            </div>
          </form>
          <div className="p-2 border border-white rounded bd-bg-gradient-up">
            <p className="fw-bold">Entry Fees: 300kr</p>
          </div>
        </div>
        {/* <div className={`d-flex justify-content-center align-items-center ${!showPart ? "form-visible" : "form-hidden"}`}>  */} 
        <div className={`${!showPart ? "form-visible" : "form-hidden"}`}> 
          <br />Team registration time has expired. <br /><br /> For further inquiry please contact to <br /> Tanjirul Islam Bappy (Ovi), 0737560199 <br /> Zahir Al-Asad(Tushar), 0760141646
        </div>
      </div>
      <Notification
        show={modalShow}
        onHide={() => setModalShow(false)}
        title={title}
        message={message}
        linkto={linkTo}
      />
    </div>
  )
}
export default RegisterTeamBd;