import React from 'react';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
// import GroupTable from './GroupTable';

function Tournament2023() {

  const [teams, setTeams] = useState([]);
  // const url = "http://localhost:8000/";
  const url = "https://ssbc.club/db/all_posts.php";
  let test = [];

  useEffect(() => {
    getTeams();
  });

  function getTeams() {
    axios.get(url)
      .then(function (response) {
        console.log(response.data);
        setTeams(response.data);
        test = response.data;
        console.log(test);

        // console.log("teams: " + teams);
        // console.log("raw:" + response.data)
      })
    // Update state;
    
  }
  const location = useLocation();
  console.log(location);
  let msg = "";
  // console.log(state);
  if (location.state === null) {
    msg = "";
  }
  else {
    msg = location.state.msg;
  }

  return (
    <div className="container">
      <div className="mt-2 p-4 p-md-5 mb-4 rounded text-bg-dark">
        <div className="p-2 border border-white rounded text-center">
          <h3>Registered teams for the tournament 2023</h3>
        </div>
        <p className='text-success'>{msg}</p>
        <h4>Boys A</h4>
        <div className="p-2 border border-white rounded text-center">
          <table className='table table-striped table-dark'>
            <thead>
              <tr>
                <th>Id</th>
                <th>Player</th>
                <th>Partner</th>
                <th>Group</th>
                <th>Phone</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {teams.map((team, key) =>
                <tr key={key}>
                  <td>{team.id}</td>
                  <td>{team.player1}</td>
                  <td>{team.player2}</td>
                  <td>{team.level}</td>
                  <td>{team.phone}</td>
                  <td>
                    <Link to={`team/${team.id}/edit`} style={{ marginRight: "10px" }}>Edit</Link>
                    {/* <button onClick={() => deleteUser(user.id)}>Delete</button> */}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <h4>Boys B</h4>
        <div className="p-2 border border-white rounded text-center">
          <table className='table table-striped table-dark'>
            <thead>
              <tr>
                <th>Id</th>
                <th>Player</th>
                <th>Partner</th>
                <th>Group</th>
                <th>Phone</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {teams.map((team, key) =>
                <tr key={key}>
                  <td>{team.id}</td>
                  <td>{team.player1}</td>
                  <td>{team.player2}</td>
                  <td>{team.level}</td>
                  <td>{team.phone}</td>
                  <td>
                    <Link to={`team/${team.id}/edit`} style={{ marginRight: "10px" }}>Edit</Link>
                    {/* <button onClick={() => deleteUser(user.id)}>Delete</button> */}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <h4>Boys C</h4>
        <div className="p-2 border border-white rounded text-center">
          <table className='table table-striped table-dark'>
            <thead>
              <tr>
                <th>Id</th>
                <th>Player</th>
                <th>Partner</th>
                <th>Group</th>
                <th>Phone</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {teams.map((team, key) =>
                <tr key={key}>
                  <td>{team.id}</td>
                  <td>{team.player1}</td>
                  <td>{team.player2}</td>
                  <td>{team.level}</td>
                  <td>{team.phone}</td>
                  <td>
                    <Link to={`team/${team.id}/edit`} style={{ marginRight: "10px" }}>Edit</Link>
                    {/* <button onClick={() => deleteUser(user.id)}>Delete</button> */}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <h4>Girls</h4>
        <div className="p-2 border border-white rounded text-center">
          <table className='table table-striped table-dark'>
            <thead>
              <tr>
                <th>Id</th>
                <th>Player</th>
                <th>Partner</th>
                <th>Group</th>
                <th>Phone</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {teams.map((team, key) =>
                <tr key={key}>
                  <td>{team.id}</td>
                  <td>{team.player1}</td>
                  <td>{team.player2}</td>
                  <td>{team.level}</td>
                  <td>{team.phone}</td>
                  <td>
                    <Link to={`team/${team.id}/edit`} style={{ marginRight: "10px" }}>Edit</Link>
                    {/* <button onClick={() => deleteUser(user.id)}>Delete</button> */}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <h4>Kids</h4>
        <div className="p-2 border border-white rounded text-center">
          <table className='table table-striped table-dark'>
            <thead>
              <tr>
                <th>Id</th>
                <th>Player</th>
                <th>Partner</th>
                <th>Group</th>
                <th>Phone</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {teams.map((team, key) =>
                <tr key={key}>
                  <td>{team.id}</td>
                  <td>{team.player1}</td>
                  <td>{team.player2}</td>
                  <td>{team.level}</td>
                  <td>{team.phone}</td>
                  <td>
                    <Link to={`team/${team.id}/edit`} style={{ marginRight: "10px" }}>Edit</Link>
                    {/* <button onClick={() => deleteUser(user.id)}>Delete</button> */}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div >
  )
}
export default Tournament2023;